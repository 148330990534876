import React, { Component } from 'react'
import Link from '../utils/link'

import Mark from '../assets/images/logo-mark.svg'
import logoLong from '../assets/images/logo-long.svg'
import logoMobile from '../assets/images/logo-long-mobile.svg'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>

          <div className='contact__logo'>
            <img className="desktop" src={logoLong} alt='McCormack Joinery' />
            <img className="mobile" src={logoMobile} alt='McCormack Joinery' />
          </div>

        <div className='footer__inner'>

          <div className='footer__col'>
            <Link to='/' title='Site Name' className='footer__logo'>
              <img src={Mark} alt='McCormack Joinery' />
            </Link>
          </div>

          <div className='footer__col'>
            <nav className='footer__nav'>
              <ul>
                <li>
                  <Link to='tel:01277 374 938'>01277 374 938</Link>
                </li>

                <li>
                  <Link to='mailto:info@mccormackjoinery.co.uk'>info@mccormackjoinery.co.uk</Link>
                </li>

                <li>
                  <Link to='https://www.instagram.com/mccormackjoinery/'>Instagram</Link>
                </li>

                <li>
                  &copy; McCormack Joinery 2023
                </li>
              </ul>
            </nav>
            
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer
