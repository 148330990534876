import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import LogoSmall from '../assets/images/logo-mark.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 100
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 20);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/#projects' title='McCormack Joinery' className='header__logo' {...props}>
              <img className='large' src={Logo} alt="McCormack Joinery" />
              <img className='small' src={LogoSmall} alt="McCormack Joinery" />
            </Link>

            <nav className='header__nav desktop'>
              <ul>
                <li><Link to='/#projects' {...props}>Projects</Link></li>
                {/* <li><Link to='/kitchens' {...props}>Standard Kitchens</Link></li> */}
                <li><Link to='/workshop' {...props}>Workshop</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>

        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Projects</Link></li>
                {/* <li><Link to='/kitchens' {...props}>Standard Kitchens</Link></li> */}
                <li><Link to='/workshop' {...props}>Workshop</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
